import React from "react";
// import Icon from "../General/Icon";

function Input({
  error,
  helper,
  handleChange = null,
  label = "",
  name = "",
  placeholder = label,
  value = "",
  defaultValue = "",
  type = "text",
  autocomplete = "on",
  register,
  required = false,
  dark = true,
  validation,
  fullWidth = true,
  className,
  cols,
  rows,
  theme,
}) {
  if (!theme) {
    theme = "form-control px-3 border-white bg-dark-primary text-white";
  }
  if (dark) {
    theme =
      "form-control px-3 border-darker-primary text-white bg-dark-primary";
  }
  return (
    <div className="row mb-3 ">
      {label && (
        <label
          className={`${fullWidth ? "col-lg-12" : "col-lg-3"} align-self-top `}
        >
          <div className="font-weight-bold">{label}</div>
          {helper && (
            <div
              className="small"
              dangerouslySetInnerHTML={{ __html: helper }}
            />
          )}
        </label>
      )}
      <div className={fullWidth ? `col-lg-12` : `col-lg-9`}>
        {type === "textarea" ? (
          <>
            {register ? (
              <textarea
                className={className + " " + theme}
                placeholder={!placeholder ? label : placeholder}
                {...register(name ? name : label, { ...validation })}
                rows={rows}
                cols={cols}
              />
            ) : (
              <textarea className={theme} value={value} type={type} />
            )}
          </>
        ) : (
          <>
            {register ? (
              <input
                className={className + " " + theme}
                type={type}
                placeholder={!placeholder ? label : placeholder}
                {...register(name ? name : label, { ...validation })}
              />
            ) : (
              <input
                className={className + " " + theme}
                placeholder={!placeholder ? label : placeholder}
                value={value}
                type={type}
              />
            )}
          </>
        )}
      </div>
      {error && (
        <div className="mt-2 col-12 small text-right opacity-80">
          <span className="px-2 py-1 bg-danger small rounded-sm">
            <i className="mr-1 fa fa-xmark-circle" />
            {error}
          </span>
        </div>
      )}
    </div>
  );
}
export default Input;
