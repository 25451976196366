import * as React from "react";
import Input from "./../components/Forms/Input";
import YesNo from "./../components/Forms/YesNo";
import "./../scss/style.scss";


const FormExample = () => {
  return (
    <>
      <header id="mainForm" className="bg-primary vh-100 p-4">
        <div className="position-relative container-fluid p-4 p-lg-5">
          <div className="row contained-xl justify-content-center">
            <div className="col-md-8">
              <div className="row contained-xl justify-content-between">
                <div className="col-6">
                  <Input placeholder="First Name" />
                </div>
                <div className="col-6">
                  <Input placeholder="Last Name" />
                </div>
                <div className="col-6">
                  <Input placeholder="Email Address" />
                </div>
                <div className="col-6">
                  <Input placeholder="Phone" />
                </div>
                <div className="col-12">
                  <Input placeholder="Post Code" />
                </div>
                <div className="col-12">
                  <Input label="Your DOB" type="date" />
                </div>
                <div className="col-12">
                  <Input label="Baby Due Date/DOB" type="date" />
                </div>

                <div className="col-6">
                  <YesNo label="First Time Parent" />
                </div>
                <div className="col-6">
                  <YesNo
                    label="Gender"
                    options={[
                      {
                        label: "Female",
                        value: "f",
                        color: "white",
                      },
                      {
                        label: "Male",
                        value: "m",
                        color: "white",
                      },
                    ]}
                  />
                </div>
                <div className="col-12 my-5">
                  <button className="btn btn-block btn-secondary">
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default FormExample;
