import React, { useState } from "react";

function YesNo({
  error,
  helper,
  handleChange = null,
  label = "",
  name = "",
  placeholder = label,
  value = "",
  defaultValue = "",
  type = "text",
  options = [
    {
      label: "Yes",
      value: "Y",
      color: "white",
    },
    {
      label: "No",
      value: "N",
      color: "white",
    },
  ],
  register,
  multiple = false,
  dark = true,
  validation,
}) {
  const [clicked, setClicked] = useState(null);

  return (
    <div className="row mt-2">
      <div className={`col-md-12 col-lg-7`}>
        {label && (
          <label className={`align-self-top mb-0 `}>
            <div className="font-weight-bold">{label}</div>
            {helper && <div className="small">{helper}</div>}
          </label>
        )}
      </div>
      <div className={`text-lg-right mt-3 col-md-12 col-lg-5`}>
        {options.map((option, index) => (
          <div
            className="btn-group btn-group-toggle"
            key={`option-${index}`}
            data-toggle="buttons"
          >
            <label
              className={`btn rounded-0 ml-1 ${
                clicked === option.value
                  ? `btn-outline-${option.color} active`
                  : `btn-outline-${option.color}`
              }`}
              htmlFor={`${name}-${option.value}`}
            >
              <input
                type="radio"
                className={`radio-${option.value}`}
                id={`${name}-${option.value}`}
                value={option.value === defaultValue ? "" : option.value}
                // {...register(name ? name : label, { ...validation })}
                onClick={() => setClicked(option.value)}
              />
              {option.label}
            </label>
          </div>
        ))}
      </div>
      {error && (
        <div className="mt-2 col-12 text-right small opacity-80">
          <span className="px-2 py-1 bg-danger small rounded-sm">
            <i className="fa fa-xmark-circle mr-1" />
            {error}
          </span>
        </div>
      )}
    </div>
  );
}
export default YesNo;
